import React from 'react'
import { useState, useCallback } from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import EZShipBoxSideForm, { EZShipBoxBackdrop } from '../components/repairs/EZShipBoxSideForm'
import RepairServices from '../components/repairs/RepairServices'
import CustomerRepairLetters from '../components/repairs/CustomerRepairLetters'
import VideoCardGrid from '../components/cms/VideoCardGrid'
import OurExperience from '../components/repairs/OurExperience'
import PropTypes from 'prop-types'

const BrandLinks = ({ children, url, className }) => {
  const combinedClassName =
    (url
      ? 'text-gray-800 underline-offset-4 duration-200 hover:text-red-700 hover:underline'
      : 'text-gray-500') + (className ? ' ' + className : '')
  return url ? (
    <Link className={combinedClassName} to={url}>
      {children}
    </Link>
  ) : (
    <span className={combinedClassName}>{children}</span>
  )
}
BrandLinks.propTypes = {
  children: PropTypes.node.isRequired,
  url: PropTypes.string,
  className: PropTypes.string,
}

const RepairsPage = () => {
  const [open, setOpen] = useState(false)
  const toggleForm = useCallback(() => setOpen(o => !o), [])

  const {
    brands: { nodes: brands },
  } = useStaticQuery(graphql`
    query WatchRepairs {
      brands: allStrapiRepairBlogCategory(sort: { fields: brand, order: ASC }) {
        nodes {
          brand
          url: urlPath
        }
      }
    }
  `)

  const videos = [
    {
      title: 'Rolex and Panerai Watchmakers | Miami Beach Watch Repair, Servicing, Overhaul (HD)',
      url: 'https://www.youtube.com/embed/xUGYxL2UpgU',
    },
    {
      title: 'Panerai Watch Repair, Servicing, Selling, and Buying | Gray and Sons',
      url: 'https://www.youtube.com/embed/Gg33rTvALOU',
    },
    {
      title:
        'Omega Watches Service & Repair - Quality and Experience defines our repair work here at Gray & Sons',
      url: 'https://www.youtube.com/embed/XBgl9H9f9c4',
    },
    {
      title:
        'Cartier Watches Service Repair Miami Beach - Cleaning, Battery Replacement, and more! - HD',
      url: 'https://www.youtube.com/embed/qkDLRVda9fU',
    },
    {
      title:
        'Vacheron Constantin Minute Repeater Perpetual Calendar Pocket Watch Restoration by Gray & Sons',
      url: 'https://www.youtube.com/embed/F_VZZhwYmGY',
    },
    {
      title: 'Patek Philippe Celestial: A look inside this rare Patek Philippe',
      url: 'https://www.youtube.com/embed/uc8NsDqZ4OA',
    },
  ]

  return (
    <Layout className={'mx-auto'} canonical={'/repairs/'}>
      <Seo
        title="Miami Beach Watch and Jewelry Repair Experts"
        canonical={'/repairs/'}
        description={
          'We are leaders in service with our Miami watch repair team. We are Master-trained and skilled at the most esteemed brands on the market.'
        }
      />
      <section>
        <header className="grid w-full bg-gray-200 ">
          <div className="container mx-auto flex flex-col items-center justify-center lg:flex-row">
            <div className="relative w-full">
              <StaticImage
                alt="Gray and Sons Jewelers Banner image"
                src={'../images/broken-rolex.png'}
                loading="lazy"
                placeholder="blurred"
              />
            </div>
            <div className="flex w-full flex-col items-center p-5 text-center">
              <h1 className="text-2xl xl:text-3xl font-bold text-black">
                MIAMI BEACH WATCH REPAIR
              </h1>
              <h2 className="2xl:text-xl">
                Gray & Sons has a team of Master trained experts with a combined 150 years of
                experience in watch making, repairing fine watches and pocket watches, detailing,
                and polishing. Conveniently located in the heart of Miami Beach across the Bal
                Harbour shops, we offer services to West Palm Beach, Sunrise, Plantation, Aventura,
                and other surrounding South Florida cities, from beginning to end. See for yourself
                the care we take in making sure that you not only have the finest watch repair
                service in the nation but that Gray & Sons keeps you informed throughout the
                process.
              </h2>
              <button className="gns-button" onClick={toggleForm}>
                QUOTE MY REPAIR
              </button>
            </div>
          </div>
        </header>
      </section>

      <div className=" flex w-full justify-center">
        <div className="flex flex-col justify-center lg:flex-row ">
          <EZShipBoxSideForm open={open} toggleForm={toggleForm} />
          <section className="order-1 mx-auto w-full max-w-[1366px] px-5">
            <div className="flex flex-col items-center">
              <h2 className="underline-title my-5 ">LUXURY WATCH BRANDS WE REPAIR</h2>
              <div className="grid w-full grid-cols-2 gap-y-3 text-sm uppercase md:grid-cols-3 md:text-base 2xl:grid-cols-5 ">
                {brands.map((arrayElement, index) => {
                  return (
                    <BrandLinks
                      key={index}
                      className={arrayElement.className}
                      url={arrayElement.url}
                    >
                      {arrayElement.brand}
                    </BrandLinks>
                  )
                })}
              </div>
              <Link
                to={'/jewelry-repair'}
                className="mt-10 block border-2  rounded-lg border-red-700 mx-auto max-w-max text-center text-red-700  px-4 py-2 text-xl hover:text-white hover:bg-red-700 hover:-translate-y-2 hover:shadow-md duration-150"
              >
                <h3>
                  <b>NEED JEWELRY REPAIR?</b>
                  <br />
                  CLICK HERE TO BEGIN THE PROCESS
                </h3>
              </Link>
            </div>
            <OurExperience />
            <RepairServices />

            <section className="my-20 prose lg:prose-xl max-w-none prose-h3:text-lg">
              <h2 className="underline-title my-5">Watch Repairs by Gray & Sons</h2>
              <StaticImage
                alt="Miami Florida Watch Repairs by Gray and Sons Jewelers"
                src={'../images/watch-repair-page-banner.jpg'}
                loading="lazy"
                className="mx-auto my-5 w-full"
              />
              <p>
                When you need a watch repair, there is only one reputable destination for your
                treasured time wear. Gray & Sons Jewelers is the most respected independent Watch
                Repair and Service Center in Miami, Florida. Serving local, national and
                international clients since 1980, we are one of the most trusted names in the
                industry for repair of luxury time wear to restore it to its original state. We take
                our watch repair department seriously, because we know how much our clients love
                their watches, especially vintage models, so we go the extra mile to make sure
                everything is done with precision and love. The process is one of several steps to
                ensure the best results. Our in-house workshop manager inspects each watch that
                comes in and consults with our Master-trained watchmakers to provide a properly
                detailed estimate of services. Watches may be photographed during the repair process
                and sent to our clients. Notes are documented in a repair report card to keep track
                of the actions and results. With a multitude of steps involved and over a century of
                combined expertise, our highly skilled team can handle any watch repair job; from
                minor repairs and basic maintenance to strap and battery replacements to bracelet
                restorations and even complete overhauls. We specialize in Split-Seconds
                Chronograph, Perpetual Calendars, and Tourbillons with our Miami watch repair
                services.
                <br />
                Some watch repair shops just take shortcuts once they have your money but that's not
                what you're going to see from our team. View our watch repair videos on our YouTube
                channel by searching for “Gray and Sons” or visit our repair shop in our Miami Beach
                showroom. With our services, there is no other choice for Miami jewelry repair. We
                repair Rolex, Cartier, Patek Philippe, Piaget, Omega, Breitling, Jaeger-LeCoultre,
                and more.
                <br />
                <br />
                Genuine is exactly what you will get. Each-pre-owned watch that we sell at <b>Gray & Sons</b> has undergone a sophisticated inspection by our master trained technicians. That means that timepieces have been tested inside and out to ensure functionality as well as timekeeping. 
                <br />
                <br />
                Whenever they need to repair or replace an individual component, our watchmakers use Swiss parts. These components, whenever possible, come from the manufacturer. Sometimes our master watchmakers fabricate and refurbish parts that are not otherwise available to us to purchase from our sources. 
              </p>
            </section>

            <section className="my-20 prose lg:prose-xl max-w-none prose-h3:text-lg">
              <h2 className="underline-title my-5">OUR REPAIR PROCESS</h2>
              <p>
                Every one of the Rolex, Patek Philippe, Cartier, Omega, Breitling, and Audemars
                Piguet watches that we take in are meticulously restored to perfection by our watch
                specialists, to ensure years of faithful service with normal care and maintenance.
                First, each watch goes through our rigorous 86-step inspection and restoration
                process. We are so confident in the quality of the timepieces we service that we
                proudly offer our exclusive 12-month warranty on all watches and a 24-month warranty
                on all Rolex watches. After being fully restored, all watch cases and bracelets are
                finely polished to remove any surface scratches and to restore each piece to its
                original luster. When a watch leaves our department (whether the watch is 5, 10, 20
                or even 50 years old) it is in pristine condition. While the watches Movement is out
                being restored by our Master Watchmakers, the bracelet and case moves on to our
                jewelry and case detailers. This department can take care of any scale job from
                light scratches to full restoration. This is how the process works:{' '}
              </p>
              <div className="grid grid-cols-1 gap-5 py-10 md:grid-cols-3">
                <div className="flex flex-col ">
                  <StaticImage
                    alt="Miami Watch Case and Bracelet Repairs by Gray and Sons Jewelers"
                    src={'../images/Gray-and-Sons-watch-repair-case-bracelet.jpg'}
                    loading="lazy"
                    className="w-full md:aspect-square"
                  />

                  <h3 className="text-lg font-bold uppercase">Case & Bracelet Repairs</h3>
                  <p>
                    Any repairs to the case and bracelet are carefully performed to exact
                    measurements and specifications
                  </p>
                </div>

                <div className="flex flex-col ">
                  <StaticImage
                    alt="Miami Watch polishing by Gray and Sons Jewelers"
                    src={'../images/Gray-and-Sons-watch-repair-polishing-wheel.jpg'}
                    loading="lazy"
                    className="w-full md:aspect-square"
                  />

                  <h3 className="text-lg font-bold uppercase">Polishing Wheels</h3>
                  <p>
                    The watch case and bracelet are then polished using a series of polishing
                    wheels. First, the back of the bracelet and the clasp are polished with a satin
                    finish. Then, we use the black rouge polishing wheel to remove deep scratches,
                    followed by the red rouge for a final high polish and the last satin finish
                    build up.
                  </p>
                </div>

                <div className="flex flex-col ">
                  <StaticImage
                    alt="Miami Watch Ultrasonic cleaner by Gray and Sons Jewelers"
                    src={'../images/Gray-and-Sons-watch-repair-ultrasonic-cleaner-thumbnail.jpg'}
                    loading="lazy"
                    className="w-full md:aspect-square"
                  />

                  <h3 className="text-lg font-bold uppercase">UltraSonic</h3>
                  <p>
                    We submerge the Rolex, Patek Philippe, Cartier, and Breitling watch bracelet and
                    the case in the UltraSonic solution to remove general build up.
                  </p>
                </div>

                <div className="flex flex-col ">
                  <StaticImage
                    alt="Miami Watch steam by Gray and Sons Jewelers"
                    src={'../images/Gray-and-Sons-watch-repair-steam-clean-thumbnail.jpg'}
                    loading="lazy"
                    className="w-full md:aspect-square"
                  />

                  <h3 className="text-lg font-bold uppercase">Steam Clean</h3>
                  <p>
                    Then we proceed by steam cleaning the watch pieces making sure we remove any
                    buildup left in between the links or hard to clean places. This step is crucial
                    in getting the piece to really excel in its true beauty.
                  </p>
                </div>

                <div className="flex flex-col ">
                  <StaticImage
                    alt="Miami Watch Drying and Repairs by Gray and Sons Jewelers"
                    src={'../images/Gray-and-Sons-watch-repair-drying.jpg'}
                    loading="lazy"
                    className="w-full md:aspect-square"
                  />

                  <h3 className="text-lg font-bold uppercase">Drying</h3>
                  <p>We lay the pieces down to dry before we move on to reassembling.</p>
                </div>
              </div>

              <p className="py-5">
                Click to view images of our watch repair and maintenance process
              </p>
              <div className="grid grid-cols-2 gap-4 md:grid-cols-3 xl:grid-cols-4">
                <Link to="/rolex-watch-repair">
                  <StaticImage
                    alt="Rolex Watch Repairs by Gray and Sons Jewelers"
                    src={'../images/Rolex-watch-repair.jpg'}
                    loading="lazy"
                    className="aspect-square w-full"
                  />
                </Link>

                <Link to="/audemars-piguet-watch-repair">
                  <StaticImage
                    alt="Audemars Piguet Watch Repairs by Gray and Sons Jewelers"
                    src={'../images/Audemars-Piguet-watch-repair-process.jpg'}
                    loading="lazy"
                    className="aspect-square w-full"
                  />
                </Link>

                <Link to="/patek-philippe-watch-repair">
                  <StaticImage
                    alt="Patek Philippe Watch Repairs by Gray and Sons Jewelers"
                    src={'../images/patek-philippe-watch-repair1.jpg'}
                    loading="lazy"
                    className="aspect-square w-full"
                  />
                </Link>

                <Link to="/cartier-watch-repair">
                  <StaticImage
                    alt="Cartier Watch Repairs by Gray and Sons Jewelers"
                    src={'../images/cartier-watch-repair1.jpg'}
                    loading="lazy"
                    className="aspect-square w-full"
                  />
                </Link>

                <Link to="/breitling-watch-repair">
                  <StaticImage
                    alt="Breitling Watch Repairs by Gray and Sons Jewelers"
                    src={'../images/breitling-watch-repair-process1.jpg'}
                    loading="lazy"
                    className="aspect-square w-full"
                  />
                </Link>

                <Link to="/vacheron-constantin-watch-repair">
                  <StaticImage
                    alt="Vacheron Constantin Watch Repairs by Gray and Sons Jewelers"
                    src={'../images/vacheron-constantin-watch-repair1.jpg'}
                    loading="lazy"
                    className="aspect-square w-full"
                  />
                </Link>

                <Link to="/piaget-watch-repair">
                  <StaticImage
                    alt="Piaget Watch Repairs by Gray and Sons Jewelers"
                    src={'../images/Piaget-watch-repairs.jpg'}
                    loading="lazy"
                    className="aspect-square w-full"
                  />
                </Link>

                <Link to="/corum-watch-repair">
                  <StaticImage
                    alt="Corum Watch Repairs by Gray and Sons Jewelers"
                    src={'../images/Corum_Romulus_repairs.jpg'}
                    loading="lazy"
                    className="aspect-square w-full"
                  />
                </Link>

                <Link to="/hublot-watch-repair">
                  <StaticImage
                    alt="Hublot Watch Repairs by Gray and Sons Jewelers"
                    src={'../images/hublot-watch-repairs.jpg'}
                    loading="lazy"
                    className="aspect-square w-full"
                  />
                </Link>

                <Link to="/corum-watch-repair">
                  <StaticImage
                    alt="Corum Twenty Dollars Watch Repairs by Gray and Sons Jewelers"
                    src={'../images/corum-twenty-dollar-watch-repair.jpg'}
                    loading="lazy"
                    className="aspect-square w-full"
                  />
                </Link>

                <Link to="/omega-watch-repair">
                  <StaticImage
                    alt="Omega Watch Repairs by Gray and Sons Jewelers"
                    src={'../images/omega-repair-image.jpg'}
                    loading="lazy"
                    className="aspect-square w-full"
                  />
                </Link>
              </div>

              <p className="py-5">
                We accept bank wire, Zelle, ACH and personal checks. Personal checks must be cleared
                before shipping.
              </p>
            </section>
            <section className="my-10">
              <h2 className="underline-title my-5">WATCH OUR REPAIR VIDEOS</h2>
              <VideoCardGrid videos={videos} />
            </section>
          </section>
        </div>
      </div>
      <div className="mx-auto my-20 w-11/12 max-w-[1366px]">
        <CustomerRepairLetters />
      </div>
      <EZShipBoxBackdrop open={open} toggleForm={toggleForm} />
    </Layout>
  )
}

export default RepairsPage
